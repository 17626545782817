import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout'
import SEO from "../components/seo";
import Header from '../components/header';
import Footer from '../components/footer';

class InvestorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state =({
      warning: false,
      infoWarning: false,
      label: "",
      quarter: "",
    })
  }

  activateWarning() {
    this.setState({
      warning: true,
    })
  }

  deactivateWarning() {
    this.setState({
      warning: false,
    })
  }

  activateInfoSiteWarning() {
    this.setState({
      infoWarning: true,
    })
  }  

  deactivateInfoSiteWarning() {
    this.setState({
      infoWarning: false,
    })
  }

  showLabel(label) {
    if (label === this.state.label) {
      this.setState({
        label: "",
        quarter: "",
      })
    } else {
      this.setState({
        label: label,
        quarter: "",
      })
    }
  }
  showQuarter(quarter) {
    if (quarter === this.state.quater) {
      this.setState({
        quarter: "",
      })
    } else {
      this.setState({
        quarter: quarter,
      })
    }
  }
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="投資人關係" />
        <Header />
        { !this.state.warning ? <span /> : 
        <div className="is-floating-warning">
          <section className="section page-content more-padding" style={{ padding: "10%", textAlign:"center"}}>
            <h6>您即將離開光群官網</h6>
            <div style={{ padding: "2em", textAlign:"left",  }}>
              <p>現在欲前往的網站並非光群雷射科技股份公司所有，而是各由其所屬之第三人所有、操縱及控制。 本站對第三人所有之網站亦無任何操縱或控制的權限。</p>
              <p>本站上之網路指示連結功能僅為提供您的便利而設。</p>
              <p>本站及本公司對該第三人所有之網站上的內容品質、效力、正確性、完整性、即時性、適法性，及該網站上之任何言論或聯結不負任何責任。</p>
              <p>本站及本公司亦無調查、監視第三人所有的網站上的內容之品質、效力、正確性、完整性、即時性、適法性的義務。本站上之網路指示連結功能無論於任何情形下，不能解釋成為對任何第三人網站的保證、背書、推薦或相類的聲明。</p>
              <p>本站及本公司特於此明確宣示對於任何第三人所有網站之內容的品質、效力、正確性、完整性、即時性及適法性不負任何明示或默示的擔保責任。 </p>
              <p>🔗 https://mis.twse.com.tw/stock/fibest.jsp?stock=2461</p>
            </div>
            <a href="https://mis.twse.com.tw/stock/fibest.jsp?stock=2461" className="link-wrapper big-link w-inline-block">
              <div className="link-text">確認前往</div>
            </a>
            <div style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.deactivateWarning()}><p>回上頁</p></div>
          </section>
        </div>} 
        { !this.state.infoWarning ? <span /> : 
        <div className="is-floating-warning">
          <section className="section page-content more-padding" style={{ padding: "10%", textAlign:"center"}}>
            <h6>您即將離開光群官網</h6>
            <div style={{ padding: "2em", textAlign:"left",  }}>
              <p>現在欲前往的網站並非光群雷射科技股份公司所有，而是各由其所屬之第三人所有、操縱及控制。 本站對第三人所有之網站亦無任何操縱或控制的權限。</p>
              <p>本站上之網路指示連結功能僅為提供您的便利而設。</p>
              <p>本站及本公司對該第三人所有之網站上的內容品質、效力、正確性、完整性、即時性、適法性，及該網站上之任何言論或聯結不負任何責任。</p>
              <p>本站及本公司亦無調查、監視第三人所有的網站上的內容之品質、效力、正確性、完整性、即時性、適法性的義務。本站上之網路指示連結功能無論於任何情形下，不能解釋成為對任何第三人網站的保證、背書、推薦或相類的聲明。</p>
              <p>本站及本公司特於此明確宣示對於任何第三人所有網站之內容的品質、效力、正確性、完整性、即時性及適法性不負任何明示或默示的擔保責任。 </p>
              <p>🔗 https://mops.twse.com.tw/mops/web/index</p>
            </div>
            <a href="https://mops.twse.com.tw/mops/web/index" className="link-wrapper big-link w-inline-block">
              <div className="link-text">確認前往</div>
            </a>
            <div style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.deactivateInfoSiteWarning()}><p>回上頁</p></div>
          </section>
        </div>} 
        <div className="page-content">
          <div className="section">
            <div className="container w-container">
              <div className="w-col w-col-9 w-col-stack">
                <div id="1">
                  <h2>公司總覽</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "Chinese" && document.node.category === "Overview") ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                  {/*<div>
                    <iframe
                      className="airtable-embed"
                      src="https://airtable.com/embed/shrcqL4NTeHZ5Ommy?backgroundColor=purple"
                      frameBorder="0"
                      width="100%"
                      height="533"
                      style={{ background:"transparent", border: "1px solid #ccc" }}
                    />
                    <p style={{ fontSize:"1em" }}>*2019年之數字係內部結算，未經會計師查核。</p>
                    <p style={{ fontSize:"1em" }}>**本公司配合主管機關法規修正，自2013年元月起，僅公告合併財務數字。</p>
                  </div>*/}
                  <div style={{ marginBottom: "3em" }}>
                    <div style={{ padding: "2em"}}>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Organizational_Chart")}
                      >
                        <div className="link-text-second">＋集團組織圖</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Sales_Report")}
                      >
                        <div className="link-text-second">＋歷年營收</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Financial_Statement")}
                      >
                        <div className="link-text-second">＋財務報表</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Financial_Report")}
                      >
                        <div className="link-text-second">＋財務報告</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Annual_Report")}
                      >
                        <div className="link-text-second">＋年報</div>
                        <div className="link-line-second"></div>
                      </button>
                    </div>
                    <div className="documentZone">
                    { this.state.label === "Organizational_Chart" ? (
                      data.Organizational_Chart.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Sales_Report" ? (
                      data.Sales_Report.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Financial_Statement" ? (
                      <div className="documentZone">
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("first")}
                        >
                          <div className="link-text-second">第一季</div>
                          <div className="link-line-second"></div>
                        </button>
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("second")}
                        >
                          <div className="link-text-second">第二季</div>
                          <div className="link-line-second"></div>
                        </button>
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("third")}
                        >
                          <div className="link-text-second">第三季</div>
                          <div className="link-line-second"></div>
                        </button>
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("fourth")}
                        >
                          <div className="link-text-second">年度</div>
                          <div className="link-line-second"></div>
                        </button>
                      <div>
                      {data.Financial_Statement.edges.map(document => (
                        <div key={document.node.id}>
                          { document.node.quarter === this.state.quarter ? 
                            <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a> : <span />
                          }
                        </div>
                      ))}    
                      </div>
                    </div>) : <span />}
                    { this.state.label === "Financial_Report" ? (
                    <div className="documentZone">
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("first")}
                      >
                        <div className="link-text-second">第一季</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("second")}
                      >
                        <div className="link-text-second">第二季</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("third")}
                      >
                        <div className="link-text-second">第三季</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("fourth")}
                      >
                        <div className="link-text-second">年度</div>
                        <div className="link-line-second"></div>
                      </button>
                      <div className="documentZone">
                      {data.Financial_Report.edges.map(document => (
                        <div key={document.node.id}>
                          { document.node.quarter === this.state.quarter ? 
                            <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a> : <span />
                          }
                        </div>
                      ))}    
                      </div>
                    </div>) : <span /> }
                    { this.state.label === "Annual_Report" ? (
                      data.Annual_Report.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    </div>
                  </div>
                </div>
                <div className="investor-section" id="2">
                  <h2>公司治理</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "Chinese" && document.node.category === "Management" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                  <div className="documentZone">
                    <div style={{ padding: "2em"}}>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Audit_Statement")}
                      >
                        <div className="link-text-second">＋內控聲明書</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Board_Resolution")}
                      >
                        <div className="link-text-second">＋董事會重要決議事項</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Internal_Policy")}
                      >
                        <div className="link-text-second">＋公司內部政策</div>
                        <div className="link-line-second"></div>
                      </button>
                    </div>
                    { this.state.label === "Audit_Statement" ? (
                      data.Audit_Statement.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Board_Resolution" ? (
                      data.Board_Resolution.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Internal_Policy" ? (
                      data.Internal_Policy.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                  </div>
                </div>
                <div className="investor-section" id="3">
                  <h2>股東專區</h2>
                  <h6>股價資訊</h6>
                  <p>請訪問以下連結至台灣證券交易所：</p>
                  <p><span style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.activateWarning() }>光群雷射 (代碼 2461) 🔗</span></p>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "Chinese" && document.node.category === "Investor" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                    <h4>重大訊息</h4>
                    <p>請訪問以下連結至台灣證券交易所公開資訊觀測站：</p>
                    <p><span style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.activateInfoSiteWarning() }>公開資訊觀測站 🔗</span></p>
                  </div>
                  <div className="documentZone">
                    <div style={{ padding: "2em"}}>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Investor_Presentation")}
                      >
                        <div className="link-text-second">＋法人說明會</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Major_Shareholders")}
                      >
                        <div className="link-text-second">＋主要股東名單</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Shareholders_Meeting")}
                      >
                        <div className="link-text-second">＋股東會資料</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Dividend_Distribution")}
                      >
                        <div className="link-text-second">＋歷年股利分配</div>
                        <div className="link-line-second"></div>
                      </button>
                    
                    </div>
                    { this.state.label === "Investor_Presentation" ? (
                      data.Investor_Presentation.edges.map(document => (
                        <div key={document.node.id}>
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Major_Shareholders" ? (
                      data.Major_Shareholders.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Shareholders_Meeting" ? (
                      data.Shareholders_Meeting.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Dividend_Distribution" ? (
                      data.Dividend_Distribution.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                  </div>
                </div>
                <div className="investor-section" id="4">
                  <h2>利害關係人專區</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "Chinese" && document.node.category === "Stakeholder" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                </div>
                <div className="investor-section" id="5">
                  <h2>企業社會責任</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "Chinese" && document.node.category === "Social" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-col w-col-3 w-col-stack">
                <div className="sidebar">
                  <div className="top-margin big">
                    <h4 className="sidebar-title dark">快速瀏覽</h4>
                    <div className="w-dyn-list">
                      <div className="w-dyn-items">
                        <div className="w-dyn-item">
                          <a href="#1" className="w-inline-block">
                            <div className="meta-blog data normal">公司總覽</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#2" className="w-inline-block">
                            <div className="meta-blog data normal">公司治理</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#3" className="w-inline-block">
                            <div className="meta-blog data normal">股東專區</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#4" className="w-inline-block">
                            <div className="meta-blog data normal">利害關係人專區</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#5" className="w-inline-block">
                            <div className="meta-blog data normal">企業社會責任</div>
                          </a>
                        </div>                                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default InvestorPage

export const pageQuery = graphql`  
  query InvestoryQuery {
    Organizational_Chart: allStrapiDocument(filter: {label: {eq: "Organizational_Chart"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Sales_Report: allStrapiDocument(filter: {label: {eq: "Sales_Report"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Financial_Statement: allStrapiDocument(filter: {label: {eq: "Financial_Statement"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Annual_Report: allStrapiDocument(filter: {label: {eq: "Annual_Report"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Financial_Report: allStrapiDocument(filter: {label: {eq: "Financial_Report"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Board_Resolution: allStrapiDocument(filter: {label: {eq: "Board_Resolution"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Audit_Statement: allStrapiDocument(filter: {label: {eq: "Audit_Statement"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Internal_Policy: allStrapiDocument(filter: {label: {eq: "Internal_Policy"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Shareholders_Meeting: allStrapiDocument(filter: {label: {eq: "Shareholders_Meeting"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Major_Shareholders: allStrapiDocument(filter: {label: {eq: "Major_Shareholders"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Investor_Presentation: allStrapiDocument(filter: {label: {eq: "Investor_Presentation"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Dividend_Distribution: allStrapiDocument(filter: {label: {eq: "Dividend_Distribution"}, language: {eq: "Chinese"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    allStrapiInvestor(sort: {fields: id, order: ASC}) {
      edges {
        node {
          id
          title
          content
          category
          language
        }
      }
    }
  }
`